import { extendTheme } from '@chakra-ui/react';
import {
  // HEADING_FONT,
  // BODY_FONT,
  // HEADING_ALTERNATIVE,
  // BODY_ALTERNATIVE,
  // CUSTOM_FONT,
  CUSTOM_HEADING_FONT,
  CUSTOM_BODY_FONT,
} from '@/constants/fonts';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  DRAWER_COLOR,
  NAVIGATION_COLOR
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    CustomFont: CUSTOM_HEADING_FONT,
    heading: CUSTOM_BODY_FONT,
    cursive: CUSTOM_BODY_FONT,
    headingAlternative: CUSTOM_BODY_FONT,
    body: CUSTOM_BODY_FONT,
    bodyAlternative: CUSTOM_BODY_FONT,
    serif: CUSTOM_BODY_FONT,
    // for generator purpose
    jost: `'Jost', sans-serif;`,
    delafield: `'Mrs Saint Delafield', cursive;`,
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    navColor: NAVIGATION_COLOR,
    drawerColor: DRAWER_COLOR,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'bodyAlternative',
      },
    },
  },
};

export default extendTheme(theme);
