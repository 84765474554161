// Mood Board Color
export const BG_PRIMARY = '#EFF4EC';
export const BG_SECONDARY = '#E5E3CF';
export const BG_ALTERNATIVE = '#E7CDC6';
export const BG_GRADIENT_TO_BOTTOM = 'linear-gradient(#e8dcd1, #F0EDE6)';
export const BG_GRADIENT_TO_TOP = 'linear-gradient(#F0EDE6, #e8dcd1)';
// Text Color
export const TEXT_PRIMARY = '#735C3D';
export const TEXT_SECONDARY = '#C99F85'; // Warana Coklat Tua sama dengan BG_ALTERNATIVE
export const TEXT_ALTERNATIVE = '#FEFEFE'; // Alternative Color
// Music & Navigation Color
export const NAVIGATION_COLOR = '#CA69A2'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';
// Default Button Color
export const BUTTON_GRADIENT = `linear-gradient(to right, #CA69A2, #E7CDC6 110%);`;