export const HEADING_FONT = `'Marcellus', cursive;`;
export const HEADING_ALTERNATIVE = HEADING_FONT;
export const BODY_FONT = HEADING_FONT;
export const BODY_ALTERNATIVE = HEADING_FONT;

// IF you want to use custom font
// change this value for heading font and change font file import in this file
// src/components/Layout/assets/styles.css
export const CUSTOM_FONT = `'Boheme Floral', 'Marcellus', cursive;`;

export const CUSTOM_HEADING_FONT = `LobsterTwo-Regular`;
export const CUSTOM_BODY_FONT = `Gabriela`;